<template>
  <div class="chart chart-line" ref="radar"></div>
</template>

<script>
import {GaugeChart} from "@toast-ui/chart";
import {onMounted, ref} from "vue";

export default {
  name: 'ToastGaugeChart',
  props: {
    name: {
      required: true,
      default: '-'
    },
    data: {
      required: true,
      default: 0
    },
    min: {
      required: true,
      default: 0
    },
    max: {
      required: true,
      default: 9
    },
    totalScore: Number,
    color: String,
    width: {
      type: Number,
      default: 330
    },
    height: {
      type: [Number, String],
      default: 330
    },
    usePlot: Boolean,
    label: String
  },
  setup(props) {
    const radar = ref(null);
    const chart = ref(null);


    const data = {
      series: [
        {
          name: [props.name],
          data: [props.data],
          color: props.color ? props.color : '#FFBC00'
        },
      ],
    };

    const options = {
      chart: {
        width: props.width, height: props.height
      },
      circularAxis: {
        scale: {
          min: props.min,
          max: props.max,
          stepSize: 0.1
        },
      },
      series: {
        solid: true,
        dataLabels: {
          visible: false,
        },
      },
      exportMenu: {
        visible: false
      },
      tooltip: {
        offsetX: -50,
        offsetY: -70,
        formatter: () => {
          // if(props.totalScore){
          //   return `${props.totalScore.toFixed(1)}점`;
          // }
          if(props.label){
            return `${props.label.substring(0, props.label.indexOf('('))}`;
          }
          return '';
        }
      },
      theme: {
        circularAxis: {
          lineWidth: 0,
          strokeStyle: 'rgba(0, 0, 0, 0)',
          tick: {
            lineWidth: 0,
            strokeStyle: 'rgba(0, 0, 0, 0)',
          },
          label: {
            color: 'rgba(0, 0, 0, 0)',
          },
        },
        tooltip: {
          background: '#FFFFFF',
          borderColor: props.color ? props.color : '#FFBC00',
          borderWidth: 1,
          borderRadius: 20,
          body:{
            color:'#111111',
          }
          // borderStyle: 'double',
        },
        series: {
          colors: [(props.color ? props.color : '#FFBC00')],
          dataLabels: {
            fontSize: 20,
            fontFamily: 'Impact',
            fontWeight: 100,
            color: (props.color ? props.color : '#FFBC00'),
            textBubble: {
              visible: false,
            },
          },
        },
      },
      usageStatistics: false
    };

    onMounted(() => {
      const el = radar.value;
      chart.value = new GaugeChart({el, data, options});
      chart.value.hideTooltip();
    });

    return {
      radar
    }
  },
};
</script>
