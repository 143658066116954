<template>
  <div class="chart chart-line" ref="radar"></div>
</template>

<script>
import {RadarChart} from "@toast-ui/chart";
import {onMounted, ref} from "vue";

export default {
  name: 'ToastRadarChart',
  props: {
    categories: {
      type: Array,
      default: () => ['자산관리', '기업금융', 'DT기획', 'DT개발', 'IB', '자본시장', '리스크', '글로벌']
    },
    series: {
      type: Array,
      default: () => [
        {
          name: 'Income',
          data: [3, 5.5, 6, 5.5, 6, 5, 5, 6],
        },
        {
          name: 'Budget',
          data: [6, 4, 2, 6, 4, 6, 4, 5],
        },
      ]
    },
    options: {
      type: Object,
      default: () => (
          {
            chart: {
              width: 415,
              height: 400
            },
            series: {
              showArea: true,
            },
            legend: {
              visible: false
            },
            theme: {
              series: {
                areaOpacity: 0.8,
                colors: ['#EEEEEE', '#FFCC00'],
                lineWidth: 1,
                // dashSegments: [10],
                dot: {
                  radius: 5,
                },
              }
            },
            exportMenu: {
              visible: false
            },
            usageStatistics: false
          }
      )
    }
  },
  setup(props) {
    const radar = ref(null);
    const chart = ref(null);

    const data = {
      categories: props.categories,
      series: props.series,
    };

    onMounted(() => {
      const el = radar.value;
      chart.value = new RadarChart({el, data, options: props.options});
      chart.value.hideTooltip();
    });

    return {
      radar
    }
  },
};
</script>
